import { IconButton } from "@g3r-developers/g3-common";
import Camera from "Components/Camera/Camera";
import sections from "Constants/Sections";
import inspectionDetailStore from "Services/DbStores/InspectionDetailStore";
import inspectionSectionStore from "Services/DbStores/InspectionSectionStore";
import { InspectionType } from "Types/Enums/InspectionType";
import { MediaType } from "Types/Enums/MediaType";
import { OfflineInspectionDetailModel } from "Types/Inspection/OfflineInspectionDetailModel";
import { QuestionGroupModel } from "Types/InspectionStaging/QuestionGroupModel";
import { QuestionAnswerModel } from "Types/Question/QuestionAnswerModel";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { LightQuestion } from "./LightQuestion";
import { QuestionCheckbox } from "./QuestionCheckbox";
import QuestionDatePicker from "./QuestionDatePicker";
import { QuestionDropdown } from "./QuestionDropdown";
import QuestionNumericTextBox from "./QuestionNumericTextBox";
import { QuestionToggle } from "./QuestionToggle";

export function QuestionComponent({
    inspectionId,
    question,
    handleChange,
    md,
}: {
    inspectionId?: string;
    question: QuestionAnswerModel;
    handleChange(questionId: string, value: string, textValue: string, asset?: string, assetMediaType?: string): void;
    md: 1 | 2 | 3 | 4 | 6 | 12;
}) {
    const [inspectionDetails, setInspectionDetails] = useState<OfflineInspectionDetailModel>(undefined);
    const [inspectionSections, setInspectionSections] = useState<QuestionGroupModel[]>(undefined);
    const [showCamera, setShowCamera] = useState<boolean>(false);
    const [photoCaptured, setPhotoCaptured] = useState<boolean>(false);

    const toggleCamera = useCallback(() => setShowCamera(p => !p), []);
    const togglePhotoCaptured = useCallback(() => setPhotoCaptured(p => !p), []);

    const questionComponent = useCallback(() => {
        switch (question?.questionType?.reference) {
            case "Toggle": {
                return (
                    <QuestionToggle
                        onChange={val => handleChange(question.id, val, val?.toString())}
                        question={question}
                    />
                );
            }
            case "InvertedToggle": {
                return (
                    <QuestionToggle
                        inverted
                        onChange={val => handleChange(question.id, val, val?.toString())}
                        question={question}
                    />
                );
            }
            case "MultiList":
            case "ComboBox": {
                return (
                    <QuestionDropdown
                        onChange={val => handleChange(question.id, val?.value, val?.label)}
                        question={question}
                    />
                );
            }
            case "NumericTextBox": {
                return (
                    <QuestionNumericTextBox
                        className="p-3"
                        onChange={val => handleChange(question.id, val?.toString(), val?.toString(), question.asset)}
                        question={question}
                    />
                );
            }
            case "CheckBox": {
                return (
                    <QuestionCheckbox
                        onChange={val => handleChange(question.id, val, val?.toString())}
                        question={question}
                    />
                );
            }
            case "DatePicker": {
                return <QuestionDatePicker onChange={val => handleChange(question.id, val, val)} question={question} />;
            }
        }
    }, [question, handleChange]);

    const isLight = useMemo(() => {
        return question.questionText.endsWith("Light");
    }, [question.questionText]);

    const showMileageCameraButton = useMemo(() => {
        return (
            inspectionDetails?.inspection?.inspectionTypeId !== InspectionType.Signal &&
            question.id === sections.interior.questions.mileage.id
        );
    }, [inspectionDetails?.inspection?.inspectionTypeId, question.id]);

    const mileagePhotoCaptured = useCallback(
        (img: string) => {
            toggleCamera();
            togglePhotoCaptured();
            handleChange(question.id, question.answer, question.answer?.toString() ?? "", img, MediaType.Photo);
        },
        [handleChange, question.answer, question.id, toggleCamera, togglePhotoCaptured]
    );

    const isMileagePhotoTaken = useMemo(() => {
        if (!inspectionId) {
            return false;
        }
        const section = inspectionSections?.find(_ => _.questionSectionId === sections.interior.id);
        const question = section?.questionItemModels?.find(
            _ => _.questionId === sections.interior.questions.mileage.id
        );
        if (question?.assetId && question.assetId !== null) {
            return true;
        }
        return photoCaptured;
    }, [inspectionId, inspectionSections, photoCaptured]);

    useEffect(() => {
        if (inspectionId) {
            inspectionDetailStore.load(inspectionId).then(setInspectionDetails);
            inspectionSectionStore.loadInspectionSections(inspectionId).then(setInspectionSections);
        }
    }, [inspectionId]);

    return (
        <>
            {!isLight && !showMileageCameraButton && (
                <>
                    {md === 12 && (
                        <>
                            <Col md={6} className="mb-3">
                                <label>{question.questionText}</label>
                            </Col>
                            <Col md={6} className="mb-3">
                                {questionComponent()}
                            </Col>
                        </>
                    )}
                    {md < 12 && (
                        <Col className="mb-3">
                            <label>{question.questionText}</label>
                            {questionComponent()}
                        </Col>
                    )}
                </>
            )}

            {isLight && (
                <>
                    <Col md="12" className="mb-3">
                        <LightQuestion question={question} handleChange={val => handleChange(question.id, val, val)} />
                    </Col>
                </>
            )}

            {showMileageCameraButton && (
                <>
                    {md === 12 && (
                        <>
                            <Col md={6} className="mb-3">
                                <label>{question.questionText}</label>
                            </Col>
                            <Col md={4} className="mb-3">
                                {questionComponent()}
                            </Col>
                            <Col md={2} className="mb-3">
                                <IconButton
                                    className=" w-100 py-3"
                                    faIconString="fas fa-camera"
                                    onClick={toggleCamera}
                                    variant={isMileagePhotoTaken ? "success" : "danger"}
                                />
                            </Col>
                        </>
                    )}

                    {md < 12 && (
                        <Col className="mb-3">
                            <label>{question.questionText}</label>
                            <Row>
                                <Col md={8}>{questionComponent()}</Col>
                                <Col md={4}>
                                    <IconButton
                                        className=" w-100 py-3"
                                        faIconString="fas fa-camera"
                                        onClick={toggleCamera}
                                        variant={isMileagePhotoTaken ? "success" : "danger"}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    )}

                    {showCamera && (
                        <Modal className="p-0 m-0" isOpen fullscreen={true}>
                            <ModalBody className="p-0">
                                <Camera
                                    description="Take a photo of the mileage reading of the vehicle"
                                    label="Mileage"
                                    onPhoto={mileagePhotoCaptured}
                                    toggleCamera={toggleCamera}
                                />
                            </ModalBody>
                        </Modal>
                    )}
                </>
            )}
        </>
    );
}
