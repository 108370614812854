import sections from "Constants/Sections";
import inspectionSectionStore from "Services/DbStores/InspectionSectionStore";
import questionSectionDisplayService from "Services/QuestionSectionDisplayService";
import { MediaType } from "Types/Enums/MediaType";
import { SectionNames } from "Types/Inspection/Enums/SectionNames";
import { OfflineInspectionDetailModel } from "Types/Inspection/OfflineInspectionDetailModel";
import { SectionModel } from "Types/Inspection/SectionModel";
import { QuestionAnswerModel } from "Types/Question/QuestionAnswerModel";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import { AudioButton } from "./AudioButton";
import { QuestionComponent } from "./QuestionComponent";

interface QuestionSectionProps {
    handleSectionComplete(index: number, state: boolean): void;
    highlightUnanswered: boolean;
    inspection: OfflineInspectionDetailModel;
    section: SectionModel;
    sectionIndex: number;
    updateInspectionDetail(inspection: OfflineInspectionDetailModel): void;
}

export function QuestionSection({
    handleSectionComplete,
    highlightUnanswered,
    inspection,
    section,
    sectionIndex,
    updateInspectionDetail,
}: QuestionSectionProps) {
    const [questions, setQuestions] = useState<QuestionAnswerModel[]>([]);

    const getQuestions = useCallback(async () => {
        const questions = await inspectionSectionStore.getSectionFromSection(inspection.inspectionId, section);
        setQuestions(questions);
    }, [section, inspection.inspectionId]);

    useEffect(() => {
        getQuestions();
    }, [getQuestions]);

    const handleChange = useCallback(
        async (questionId: string, newValue: string, textValue: string, asset?: string, assetMediaType?: MediaType) => {
            await inspectionSectionStore.updateInspectionSectionAnswer({
                inspectionId: inspection.inspectionId,
                sectionId: section.id,
                questionId: questionId,
                answer: newValue,
                textValue: textValue,
                asset: asset,
                assetMediaType: assetMediaType,
            });
            getQuestions();
        },
        [getQuestions, inspection.inspectionId, section]
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async function asyncForEach(array: any[], callback: (value: any, index: number, array: any[]) => Promise<any>) {
        for (let index = 0; index < array.length; index++) {
            // eslint-disable-next-line no-await-in-loop
            await callback(array[index], index, array);
        }
    }

    const defaultLights = useCallback(async () => {
        const lights = questions.filter(q => q.questionText.endsWith("Light") && q.answer === undefined);

        if (!lights || !lights.length) {
            return;
        }

        await asyncForEach(lights, async light => {
            await handleChange(light.id, "not illuminated", "not illuminated");
        });

        getQuestions();
    }, [questions, handleChange, getQuestions]);

    useEffect(() => {
        if (!questions) {
            return;
        }

        defaultLights();
    }, [defaultLights, questions]);

    const display = useCallback(
        (question: QuestionAnswerModel) => {
            switch (section.name) {
                case SectionNames.VehicleDetails: {
                    return questionSectionDisplayService.VehicleDetailsDisplay(question, questions);
                }
                case SectionNames.MechianicalCondition: {
                    return questionSectionDisplayService.MechanicalConditionDisplay(question, questions);
                }
                default: {
                    return true;
                }
            }
        },
        [questions, section.name]
    );

    const md = useMemo(() => {
        if (
            section.name === SectionNames.VehicleEquipment ||
            section.name === "Interior" ||
            section.name === "Exterior" ||
            section.name.includes("Assured") ||
            section.name.includes("Express")
        ) {
            return 4;
        }

        return 12;
    }, [section.name]);

    useEffect(() => {
        if (section.name === SectionNames.VehicleEquipment) {
            if (questions.filter(q => q.answer !== undefined).length > 0) {
                handleSectionComplete(sectionIndex, true);
            }
        } else if (section.name === SectionNames.VehicleDetails) {
            const vehicleServiceHistoryQId = "0f2b328e-af16-4ff0-bf76-d2494f34c907";
            const noOfServiceStampsQId = "e2d26dd0-36b2-438b-bcb8-0f7d6da9ffbf";
            const noneAId = "800cfcda-9ec5-4665-92b9-977c554c5b39";

            const serviceHistoryQ = questions.find(q => q.id === vehicleServiceHistoryQId);
            const serviceHistoryQAId = serviceHistoryQ?.answer;

            /* "Number of Service Stamps" Question is not required if 
                "Vehicle Service History" Question has the answer "None" */
            if (serviceHistoryQAId === noneAId) {
                if (
                    section.questions.filter(q => q.id !== noOfServiceStampsQId).length ===
                    questions.filter(q => q.answer !== undefined && q.id !== noOfServiceStampsQId).length
                ) {
                    handleSectionComplete(sectionIndex, true);
                } else {
                    handleSectionComplete(sectionIndex, false);
                }
            } else {
                if (section.questions.length === questions.filter(q => q.answer !== undefined).length) {
                    handleSectionComplete(sectionIndex, true);
                } else {
                    handleSectionComplete(sectionIndex, false);
                }
            }
        } else if (section.name === SectionNames.Interior) {
            // Ensure an asset exists for the mileage question if applicable
            if (questions.some(q => q.id === sections.interior.questions.mileage.id && !q.asset)) {
                handleSectionComplete(sectionIndex, false);
                return;
            }
            if (
                section.questions.length ===
                questions.filter(q => q.answer !== undefined && q.answer.trim() !== "").length
            ) {
                handleSectionComplete(sectionIndex, true);
            }
        } else {
            if (section.questions.length === questions.filter(q => q.answer !== undefined).length) {
                handleSectionComplete(sectionIndex, true);
            }
        }
    }, [questions, section, handleSectionComplete, sectionIndex]);

    const getQuestionClasses = useCallback(
        (hasError?: boolean) => {
            let classes = ["align-items-center", "h-100", "m-1"];

            if (highlightUnanswered && hasError) {
                classes = classes.concat(["bg-danger", "bg-opacity-75", "rounded"]);
            }

            return classes.join(" ");
        },
        [highlightUnanswered]
    );

    return (
        <Row className={"align-items-stretch"}>
            {questions.map(q => (
                <Col className={"mb-1"} key={q.id} md={md}>
                    <Row className={getQuestionClasses(!q.answer)}>
                        {display(q) && (
                            <QuestionComponent
                                inspectionId={inspection?.inspectionId}
                                md={md}
                                question={q}
                                handleChange={handleChange}
                            />
                        )}
                    </Row>
                </Col>
            ))}

            {section.name === "Inspection" && (
                <>
                    <Col className={"mb-1"}>
                        <Row className={getQuestionClasses(!inspection?.inspection?.audioAssetId)}>
                            <Col md="12">
                                <h5 className="text-bold">Audio Recording</h5>
                                <AudioButton inspection={inspection} updateInspectionDetail={updateInspectionDetail} />
                            </Col>
                        </Row>
                    </Col>
                </>
            )}
        </Row>
    );
}
