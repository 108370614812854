import { G3Dropdown, IconButton, Loading, showconfirmmodal } from "@g3r-developers/g3-common";
import InspectionStoredStatusBadge from "Components/Inspection/InspectionStoredStatusBadge";
import { useIsAuthenticated } from "Hooks/UseSession";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row, Table } from "reactstrap";
import inspectionAssetStore from "Services/DbStores/InspectionAssetStore";
import inspectionDetailStore from "Services/DbStores/InspectionDetailStore";
import dropdownService from "Services/DropdownService";
import inspectionService from "Services/InspectionService";
import { DateTimeFormat } from "Services/Utils";
import { Transmission, VehicleType } from "Types/Inspection/Enums/VehicleType";
import { InspectionModel } from "Types/Inspection/InspectionModel";

export default function ConfirmInspection(): JSX.Element {
    const { inspectionId } = useParams<{
        inspectionId?: string;
    }>();

    const session = useIsAuthenticated();

    const [hasStoredInspection, setHasStoredInspection] = useState<boolean>(false);
    const [initialInspectionState, setInitialInspectionState] = useState<InspectionModel>(undefined);
    const [inspection, setInspection] = useState<InspectionModel>(undefined);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    const navigate = useNavigate();

    const fullDescription = useMemo(() => {
        if (!inspection) {
            return "";
        }

        return `${inspection.vehicleManufacturer.name} ${inspection.vehicleModel.model} ${inspection.vehicleType.display}`;
    }, [inspection]);

    const loadInspection = useCallback(
        async (inspectionId: string) => {
            try {
                let inspection: InspectionModel = undefined;

                const storedInspectionDetail = await inspectionDetailStore.load(inspectionId);

                if (storedInspectionDetail) {
                    setHasStoredInspection(true);
                    inspection = storedInspectionDetail.inspection;
                } else {
                    inspection = await inspectionService.getById(inspectionId);
                }

                setInspection(inspection);
                setInitialInspectionState(inspection);
            } catch (e) {
                alert(e.message);
                navigate("/");
            } finally {
                setLoaded(true);
            }
        },
        [navigate]
    );

    const getAndSaveInspectionToDevice = useCallback(
        async (inspectionId: string) => {
            const inspectionDetail = await inspectionService.start(inspectionId);

            await inspectionService.storeDetail(inspectionDetail);

            await loadInspection(inspectionId);
        },
        [loadInspection]
    );

    const inspectionHasChanges = useMemo(() => {
        if (!initialInspectionState || !inspection) {
            return false;
        }

        const propertyChecks = Object.keys(inspection).map(k => inspection[k] === initialInspectionState[k]);
        const hasChanges = !propertyChecks.every(check => check);
        return hasChanges;
    }, [initialInspectionState, inspection]);

    const handleSave = useCallback(async () => {
        try {
            setIsDownloading(true);

            if (inspectionHasChanges) {
                await inspectionService.update(inspectionId, inspection);
            }

            if (hasStoredInspection && !inspectionHasChanges) {
                const shouldRedownload = await showconfirmmodal(
                    "Inspection Already Dowloaded",
                    "We've detected this inspection is already saved to the device for offline use. Would you like to re-download it? Warning: If the inspection is in progress, this may overwrite any local changes."
                );

                if (shouldRedownload) {
                    await getAndSaveInspectionToDevice(inspectionId);
                }
            } else {
                await getAndSaveInspectionToDevice(inspectionId);
            }

            await inspectionAssetStore.storeGenericAssetsIfRequired();

            setIsDownloading(false);

            const shouldProceedToInspection = await showconfirmmodal(
                "Inspection Downloaded",
                "This inspection is saved to the device for offline use. Do you want to proceed to the inspection?"
            );

            if (shouldProceedToInspection) {
                navigate(`/inspection/${inspectionId}`, { replace: true });
            }
        } catch (e) {
            alert(e.message);
            alert(e);
            // history.push("/");
        }
    }, [getAndSaveInspectionToDevice, hasStoredInspection, navigate, inspection, inspectionHasChanges, inspectionId]);

    const handleVehicleTypeChanged = (newValue: string) => {
        setInspection(p => {
            return { ...p, vehicleTypeId: newValue };
        });
    };

    const handleInspectionTypeChanged = (newValue: string) => {
        setInspection(p => {
            return { ...p, inspectionTypeId: newValue };
        });
    };

    const handleInspectionProfileChanged = (newValue: string) => {
        setInspection(p => {
            return { ...p, inspectionProfileId: newValue };
        });
    };

    const handleTransmissionTypeChanged = (newValue: string) => {
        setInspection(p => {
            return { ...p, transmissionId: newValue };
        });
    };

    const errors = useMemo(() => {
        const e = { vehicleTypeId: undefined, transmissionTypeId: undefined };
        if (inspection?.vehicleTypeId === VehicleType.Unknown) {
            e.vehicleTypeId = "Confirm Vehicle Type";
        }

        if (session && inspection?.transmissionId === Transmission.Unknown) {
            e.transmissionTypeId = "Confirm Transmission";
        }

        return e;
    }, [inspection?.transmissionId, inspection?.vehicleTypeId, session]);

    const confirmDisabled = useMemo(() => {
        return errors.transmissionTypeId !== undefined || errors.vehicleTypeId !== undefined;
    }, [errors]);

    useEffect(() => {
        loadInspection(inspectionId);
    }, [inspectionId, loadInspection]);

    const saleDate = useMemo(() => {
        if (!inspection) {
            return "";
        }
        if (!inspection.dateInSale) {
            return "TBC";
        }
        return DateTime.fromISO(inspection.dateInSale).toFormat(DateTimeFormat);
    }, [inspection]);

    return (
        <>
            {loaded ? (
                <Container className="p-5">
                    <Row>
                        <Col>
                            <h3>Confirm Inspection Details</h3>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>Please check the details below</Col>
                    </Row>
                    <Row>
                        <Col md="3">
                            <Row>
                                <Col className="text-center">
                                    <img src="https://media.g3r.co.uk/legacy/no-image.jpg" height="150" alt="Vehicle" />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="text-center">
                                    <span className="form-control bg-warning">
                                        <h3 className="text-bold mb-0">{inspection.vrm}</h3>
                                    </span>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="text-center">
                                    <InspectionStoredStatusBadge stored={hasStoredInspection} />
                                </Col>
                            </Row>
                        </Col>
                        <Col md="9">
                            <Table>
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>
                                            <h3 className="text-primary">{fullDescription}</h3>
                                        </td>
                                    </tr>
                                    <tr className="my-2">
                                        <td>Make: {inspection.vehicleManufacturer.name}</td>
                                        <td>Colour: {inspection.colour.display}</td>
                                    </tr>
                                    <tr>
                                        <td>Model: {inspection.vehicleModel.model}</td>
                                        <td>VIN: {inspection.vin}</td>
                                    </tr>
                                    <tr>
                                        <td>Fuel Type: {inspection.fuelType.display}</td>
                                        <td>Transmission: {inspection.transmission.display}</td>
                                    </tr>
                                    {session && (
                                        <tr>
                                            <td>Vendor: {inspection.vendor}</td>
                                            <td>Sale Date: {saleDate}</td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td>
                                            <G3Dropdown
                                                disabled={isDownloading}
                                                label="Vehicle Type"
                                                loadOptions={dropdownService.vehicleType}
                                                defaultOption={inspection.vehicleTypeId}
                                                onChange={handleVehicleTypeChanged}
                                                isSearchable={false}
                                                error={errors.vehicleTypeId}
                                            />
                                        </td>
                                        <td>
                                            <G3Dropdown
                                                disabled={isDownloading}
                                                label="Inspection Type"
                                                loadOptions={dropdownService.inspectionType}
                                                defaultOption={inspection.inspectionTypeId}
                                                onChange={handleInspectionTypeChanged}
                                                isSearchable={false}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <G3Dropdown
                                                disabled={isDownloading}
                                                label="Inspection Profile"
                                                loadOptions={dropdownService.inspectionProfile}
                                                defaultOption={inspection.inspectionProfileId}
                                                onChange={handleInspectionProfileChanged}
                                                isSearchable={false}
                                            />
                                        </td>
                                        {session && (
                                            <td>
                                                <G3Dropdown
                                                    disabled={isDownloading}
                                                    label="Transmission Type"
                                                    loadOptions={dropdownService.transmission}
                                                    defaultOption={inspection.transmissionId}
                                                    onChange={handleTransmissionTypeChanged}
                                                    isSearchable={false}
                                                    error={errors.transmissionTypeId}
                                                />
                                                {errors.transmissionTypeId && (
                                                    <span className="text-danger">{errors.transmissionTypeId}</span>
                                                )}
                                            </td>
                                        )}
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col xs={12}>
                            {!isDownloading && (
                                <IconButton
                                    disabled={confirmDisabled}
                                    block
                                    text="Confirm Inspection"
                                    onClick={handleSave}
                                    variant={"primary"}
                                />
                            )}
                            {isDownloading && <Loading text="Downloading Inspection" />}
                        </Col>
                    </Row>
                </Container>
            ) : (
                <Loading text="Loading Inspection Details" />
            )}
        </>
    );
}
